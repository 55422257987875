<template>
  <v-dialog v-model="value" persistent width="450px">
    <v-card outlined>
      <v-card-title>
        <h4 class="primary--text">
        {{ $t('companiesDialog.title') }}
        </h4>
      </v-card-title>
      <v-card-text>
        <ul>
          <li>Company 1</li>
          <li>Company 2</li>
          <li>Company 3</li>
        </ul>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close" depressed>
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CompaniesDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
