<template>
  <v-main class="register">
    <v-container fluid class="h-full pa-0">
      <v-row no-gutters class="primary white--text px-8 py-3">
        <v-col cols="12" md="3" class="d-flex align-center mb-2 mb-md-0">
          <v-img
            eager
            :transition="false"
            :src="require('@/assets/logo/SPEDIMO_logo_white.svg')"
            max-width="150px"
            width="150px"
            height="20px"
            class="mx-md-2 px-md-1"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" class="d-flex flex-column justify-center">
          <h1 class="text-h5 font-weight-bold">
            {{ $t('register.title') }}
          </h1>
          <h2 class="text-body-2">
            {{ $t('register.subtitle') }}
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-stepper alt-labels flat v-model="step">
            <v-row no-gutters>
              <v-col cols="12" md="6" offset-md="3">
                <v-btn color="primary" @click="redirect" depressed class="register-btn">
                  <span class="register-return-btn-text-full-width">
                  <v-icon class="pointer"> mdi-chevron-left </v-icon>{{ $t('register.returnToLoginPage') }}</span>
                  <v-icon class="pointer register-return-btn-text-small-width"> mdi-chevron-left </v-icon>
                </v-btn>
                <v-stepper-header class="elevation-0">
                  <template
                    v-for="item in stepperHeader"
                  >
                    <v-stepper-step :key="`step-${item.label}`" :step="item.step" :complete="item.complete">
                      {{ item.label }}
                    </v-stepper-step>
                    <v-divider v-if="item.step !== 4" :key="`divider-${item.label}`" />
                  </template>
                </v-stepper-header>
              </v-col>
            </v-row>
            <v-row no-gutters class="register-content">
              <v-col cols="12" md="8" offset-md="2">
                <v-stepper-items
                  alt-labels
                >
                  <v-stepper-content step="1">
                    <RegisterCompanyForm
                      v-if="step === 1"
                      :value="forms.company"
                      @next="next"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <RegisterPersonalForm
                      v-if="step === 2"
                      :value="forms.personal"
                      @prev="prev"
                      @next="next"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="3">
                      <RegisterDocumentsForm
                        v-if="step === 3"
                        :documents="forms.documents"
                        @remove="removeDocument"
                        @prev="prev"
                        @next="next"
                      />
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <RegisterSummaryForm
                      v-if="step === 4"
                      :loading="loading"
                      @prev="prev"
                      @submit="submitRegisterHandle"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="5">
                    <RegisterSuccessForm
                      v-if="step === 5"
                      :company-id="companyId"
                    />
                  </v-stepper-content>
                </v-stepper-items>
              </v-col>
            </v-row>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
    <Notification/>
  </v-main>
</template>

<script>
import services from '@/services'
import RegisterCompanyForm from '@/views/auth/components/RegisterCompanyForm'
import Notification from '@/components/Notification'
import RegisterPersonalForm from '@/views/auth/components/RegisterPersonalForm'
import RegisterDocumentsForm from '@/views/auth/components/RegisterDocumentsForm'
import RegisterSummaryForm from '@/views/auth/components/RegisterSummaryForm'
import RegisterSuccessForm from '@/views/auth/components/RegisterSuccessForm'
import moment from 'moment'

export default {
  name: 'Register',
  components: {
    Notification,
    RegisterCompanyForm,
    RegisterPersonalForm,
    RegisterDocumentsForm,
    RegisterSummaryForm,
    RegisterSuccessForm
  },
  data () {
    return {
      step: 1,
      stepperHeader: [
        {
          step: 1,
          label: this.$t('register.step1.header'),
          complete: false
        },
        {
          step: 2,
          label: this.$t('register.step2.header'),
          complete: false
        },
        {
          step: 3,
          label: this.$t('register.step3.header'),
          complete: false
        },
        {
          step: 4,
          label: this.$t('register.step4.header'),
          complete: false
        }
      ],
      forms: {
        company: {
          VatNumber: '',
          CompanyName: '',
          Street: '',
          ZipCode: '',
          City: '',
          Country: ''
        },
        personal: {
          userName: '',
          surname: '',
          email: '',
          phoneNumber: '',
          language: ''
        },
        documents: [],
        order: {
          privacyPolicy: false,
          rodo: false
        }
      },
      loading: false,
      companyId: ''
    }
  },
  methods: {
    redirect () {
      window.location.href = 'https://spedimo.eu'
    },
    prev () {
      if (this.step <= 1) {
        return
      }
      this.step--
    },
    next (event) {
      if (this.step >= 5) {
        return
      }
      if (this.step === 1) {
        this.forms.company = event
      } else if (this.step === 2) {
        this.forms.personal = event
      } else if (this.step === 3) {
        this.forms.documents = event
      }
      const currentStep = this.stepperHeader.find(item => item.step === this.step)
      if (currentStep) {
        currentStep.complete = true
      }
      this.step++
    },
    removeDocument (document) {
      this.forms.documents = this.forms.documents.filter((item) => item !== document)
    },
    async submitRegisterHandle () {
      this.loading = true
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('RegisterCompany')

        const formData = new FormData()

        formData.append('CaptchaToken', token)

        const objectToFormData = (formData, object) => {
          for (const property in object) {
            formData.append(property, object[property])
          }
        }

        objectToFormData(formData, this.forms.company)
        objectToFormData(formData, this.forms.personal)

        const mapDocuments = (documentList) => {
          return documentList
            .filter(document => document.files && document.files.length > 0)
            .map(document => {
              const doc = {
                type: 1
              }
              doc['filesWrapper.files'] = [
                ...document.files
              ]
              doc.fileNames = document.fileNames
              doc.description = document.description
              doc.type = document.type
              if (document.validIndefinitely) {
                doc.validIndefinitely = document.validIndefinitely
                doc.validFrom = moment().startOf('day').format()
                doc.validTo = moment().endOf('day').format()
              } else {
                doc.validFrom = moment().startOf('day').format()
                doc.validTo = moment(document.validTo).endOf('day').format()
              }

              return doc
            })
        }
        const documents = mapDocuments(this.forms.documents)

        console.log('Po mapowaniu')
        console.log(documents)

        let documentIndex = 0

        documents.forEach(item => {
          for (const property in item) {
            if (property === 'fileNames') {
              item[property].forEach(file => {
                formData.append(`Documents[${documentIndex}].FileNames`, file)
              })
            } else {
              formData.append(`Documents[${documentIndex}].${property}`, item[property])
            }
          }
          documentIndex = documentIndex + 1
        })
        const response = await services.post('Company/RegisterCompany', formData)
        this.companyId = response.data
      } finally {
        this.loading = false
      }
      const currentStep = this.stepperHeader.find(item => item.step === this.step)
      if (currentStep) {
        currentStep.complete = true
      }
      this.step++
    }
  }
}
</script>

<style lang="scss" scoped>
.register,
.register-content {
  background-color: var(--v-background-base);
}

.register-btn{
  position: absolute;
    left: 50px;
    top: 25px;
}

.register-return-btn-text-small-width{
  display:none;
}

@media screen and (max-width: 960px)
{
  .register-btn{
    left:20px;
    padding: 0 !important;
    margin: 0;
    max-width: 30px  !important;
    min-width: 10px  !important;
    top: 20px;
  }

  .register-return-btn-text-full-width{
    display: none;
  }

  .register-return-btn-text-small-width{
    display: block;
    z-index: 1000;
  }
}
</style>
