<template>
  <div class="register-documents-form">
    <v-card class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">{{ $t('issueRegister.add') }}</h4>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <label>{{ $t('issueRegister.documentType') }}</label>
            <v-select
              v-model="addDocumentType"
              :items="documentTypes"
              item-text="description"
              item-value="id"
              dense
              outlined
              clearable />
            <DocumentNew v-if="addDocumentType" :type="addDocumentType" :loading="loadingFiles" :uploadValue="uploadValue" @submit="addFile" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-for="(document, index) in documents" :key="index" class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">
          {{ document.type | getDescription(documentTypes) }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <label :for="`documentName-${index}`">{{ $t('companiesDetails.name') }}</label>
            <v-text-field
              :value="document.description"
              :id="`documentName-${index}`"
              outlined
              dense
              single-line
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <label>{{ $t('register.step3.expirationDate') }}: </label>
            <span v-if="document.validIndefinitely">{{ $t('register.step3.indefinitely') }}</span>
            <span v-else>{{ document.validTo }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col cols="12" md="8">
            <ItemList class="mb-4" :items="document.files" :remove="false" @download="getFile" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn color="primary" outlined class="mr-2" @click="removeFile(document)">
              {{ $t('common.remove') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row no-gutters class="mb-8">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="secondary" outlined class="mr-2" @click="$emit('prev')">
          {{ $t('register.prev') }}
        </v-btn>
        <v-btn color="primary" @click="next" :disabled="!!addDocumentType" depressed>
          {{ $t('register.next') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import services from '@/services'
import { isFile, downloadLocalFile } from '@/utils/helpers'
import DocumentNew from '@/views/Companies/Market/CompanyDetails/Tabs/Tabs/DocumentNew.vue'
import ItemList from '@/components/ItemList.vue'

export default {
  name: 'RegisterCompanyForm',
  components: {
    DocumentNew,
    ItemList
  },
  props: ['documents'],
  data () {
    return {
      addDocumentType: null,
      loadingFiles: false,
      uploadValue: 0,
      rules: {
        description: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ]
      }
    }
  },
  computed: {
    documentTypes () {
      return this.$store.getters.enums.documentTypes
    }
  },
  methods: {
    next () {
      this.$emit('next', this.documents)
    },
    async addFile (document) {
      try {
        this.loadingFiles = true
        this.uploadValue = 0
        var sizeTooBig = false
        var newFilesSize = 0

        document.files.forEach(item => {
          if (item.size > 10485760) {
            sizeTooBig = true
          }
          newFilesSize = newFilesSize + item.size
          var formData = new FormData()
          formData.append('FilesWrapper.Files', item, item.name)
        })

        if (sizeTooBig) {
          await this.$store.dispatch('setErrorNotification', this.$t('register.singleSizeError'))
          return
        }

        this.documents.forEach(doc => {
          doc.files.forEach(item => {
            newFilesSize = newFilesSize + item.size
          })
        })

        if (newFilesSize > (1048576 * 50)) {
          await this.$store.dispatch('setErrorNotification', this.$t('register.allSizeError'))
          return
        }

        console.log(document.files.length)
        document.fileNames = []
        for (var i = 0; i < document.files.length; i++) {
          var item = document.files[i]
          var formData = new FormData()
          formData.append('FilesWrapper.Files', item, item.name)
          const newRes = await services.post('Company/AddTemporaryDocument', formData, {},
            (e) => {
              if (e.lengthComputable) {
                this.uploadValue = (e.loaded / e.total * 100) / (document.files.length - i)
              }
            })
          document.fileNames.push(newRes.data)
        }

        this.documents.push(document)
        this.addDocumentType = null
      } finally {
        this.loadingFiles = false
      }
    },
    async removeFile (document) {
      this.$emit('remove', document)
    },
    async getFile (file) {
      if (isFile(file)) {
        downloadLocalFile(file)
      }
    }
  }
}
</script>
