<template>
  <div class="register-presonal-form">
    <v-card class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('register.step4.headerTitle') }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="registerSummaryForm">
          <v-row no-gutters>
            <v-col cols="12" md="10" class="d-flex align-center">
              <v-checkbox v-model="forms.privacyPolicy" :rules="rules.privacyPolicy" hide-details>
                <template v-slot:label>
                  <div @click.stop v-html="$t('register.step4.termsOfUse')">
                </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="10" class="d-flex align-center">
              <v-checkbox v-model="forms.rodo" :rules="rules.privacyPolicy" hide-details>
                <template v-slot:label>
                   <div @click.stop v-html="$t('register.step4.privacyPolicy')"></div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-8">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="primary"
                :disabled="!valid || loading"
                :loading="loading"
                @click="submitHandle"
                depressed
              >
                {{ $t('register.step4.submit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row no-gutters class="mb-8">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          color="secondary"
          outlined
          class="mr-2"
          @click="$emit('prev')"
        >
          {{ $t('register.prev') }}
        </v-btn>
        <v-btn color="primary" disabled depressed>
          {{ $t('register.next') }}
        </v-btn>
      </v-col>
    </v-row>
    <CompaniesDialog
      :value="companiesDialog"
      @close="closeCompaniesDialog"
    />
  </div>
</template>

<script>
import CompaniesDialog from '@/views/auth/dialogs/CompaniesDialog'

export default {
  name: 'RegisterSummaryForm',
  components: {
    CompaniesDialog
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        privacyPolicy: false,
        rodo: false
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      forms: {
        privacyPolicy: false,
        rodo: false
      },
      rules: {
        privacyPolicy: [
          v => v || this.$t('validation.fieldRequired')
        ],
        rodo: [
          v => v || this.$t('validation.fieldRequired')
        ]
      },
      valid: true,
      companiesDialog: false
    }
  },
  methods: {
    prev () {
      if (this.e1 <= 1) {
        return
      }
      this.e1--
    },
    submitHandle () {
      this.$refs.registerSummaryForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit')
    },
    openCompaniesDialog () {
      this.companiesDialog = true
    },
    closeCompaniesDialog () {
      this.companiesDialog = false
    }
  }
}
</script>
